import styles from "./ImageContainer.module.scss";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
	className?: string;
	fullWidth?: boolean;
	flip?: boolean;
}

export const ImageContainer = ({ children, className, fullWidth,flip }: Props) => {
	return (
		<div
			className={`${styles.imageContainer} ${className && className} ${
				fullWidth && styles.fullWidth} ${flip && styles.flip}`}>
			{children}
		</div>
	);
};
