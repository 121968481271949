import styles from "./TitleWrapper.module.scss";

interface Props {
	title: string;
	subtitle?: string;
	textAlign: "left" | "right" | "center";
}

export const TitleWrapper = ({ title, subtitle, textAlign }: Props) => {
	return (
		<div className={`${styles.titleWrapper} ${styles[textAlign]}`}>
			<h4>{subtitle}</h4>
			<h2>{title}</h2>
		</div>
	);
};
